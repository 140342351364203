var stylesData = {
  buttonStyle:{
    borderColor:"rgb(88, 139, 239)",
    borderWidth:1,
    borderStyle: 'solid',
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center",
    borderBottomRightRadius:10,
    borderBottomLeftRadius:10,
    borderTopRightRadius:10,
    borderTopLeftRadius:10,
    backgroundColor: "white",    
    margin:10,
    width:150,
    height:50
},
 button:{
   color:"rgb(88, 139, 239)"
 },
 topIconContainer:{
   marginLeft:5,
   marginRight:5
 },
 iconcontainer:{
 display:"flex",
 flexDirection:"column",
 justifyContent:"center",
 alignItems:"center"
},
iconText:{
 color:"rgba(72,128,237,1)"
},
iconTextLight:{
 color:"#FFFFFF"
},
checkboxTextBox:{
     width:40,
     height:40,
     borderWidth:1,
     borderStyle: 'solid',
     borderColor:"#888888",
     display:"flex",
     flexDirection:"row",
     justifyContent:"center",
     alignItems:"center"
},
checkboxText:{
 fontSize: 36,
 fontWeight: 'bold',
 fontFamily: 'Futura-Medium',
 top:10
},
clipboardButtonContainer:{
   display:"flex",
   flexDirection:"row",
   justifyContent:"flex-start",
   alignItems:"center"
}

};


var styles = stylesData;
export { styles };